<template>
    <div class="silderEdit" v-loading="loading">
        <el-form
            ref="tenantForm"
            :model="form"
            :rules="rules"
            label-width="1.8rem"
            label-position="right"
        >
            <div class="fonm_list">
                <h3 class="iconfont icon-leibie">
                    基本信息
                </h3>
                <el-form-item label="部门名称" prop="OrgName">
                    <el-input
                        maxlength="30"
                        show-word-limit
                        placeholder="部门名称"
                        v-model="form.OrgName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="部门简称">
                    <el-input
                        maxlength="20"
                        show-word-limit
                        placeholder="部门简称"
                        v-model="form.OrgShortName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="部门编码">
                    <el-input v-model="form.OrgCode" placeholder="部门编码" :disabled="isExternal"></el-input>
                </el-form-item>
                <el-form-item label="部门类型" prop="OrgType">
                    <el-radio-group v-model="form.OrgType" :disabled="isExternal">
                        <el-radio :label="8">
                            部门
                        </el-radio>
                        <el-radio :label="7">
                            场站
                        </el-radio>
                        <el-radio :label="9" :disabled="isExternal == false">
                            外部部门
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="上级部门" prop="AllParentId">
                    <el-cascader
                        clearable
                        v-model="form.AllParentId"
                        :options="superiorDepartment"
                        :props="{
                            checkStrictly: true,
                            label: 'OrgName',
                            value: 'OrgId',
                            children: 'Childs',
                        }"
                        @change="selectTenant"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="特殊属性">
                    <el-select v-model="form.DeptTypeCode" placeholder="请选择">
                        <el-option
                            v-for="item in deptTypeLIst"
                            :key="item.DicCode"
                            :label="item.DicName"
                            :value="item.DicCode"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="主管领导">
                    <el-input placeholder="请输入内容" v-model="form.LeaderName">
                        <template slot="append">
                            <el-button slot="append" icon="el-icon-user" @click="selectUser('selectLeader')"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="部门负责人">
                    <el-input placeholder="请输入内容" v-model="form.ExecutiveName">
                        <template slot="append">
                            <el-button slot="append" icon="el-icon-user" @click="selectUser('selectExecutive')"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="备注信息">
                    <el-input
                        type="textarea"
                        :rows="5"
                        v-model="form.Remarks"
                    ></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="SortNum">
                    <el-input type="number" v-model="form.SortNum"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="dialog-footer">
            <div class="btn save-btn" @click="submitForm">
                保存
            </div>
            <div class="btn close-btn" @click="$emit('close')">
                关闭
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        currentNode: Object,
        editType: String,
    },
    data() {
        return {
            loading: false,
            // 上级部门数据
            superiorDepartment: [],
            // 特殊属性选项
            deptTypeLIst: [],
            form: {
                TenantId: '',
                TenantName: '',
                OrgId: '',
                OrgName: '',
                OrgShortName: '',
                OrgCode: '',
                OrgType: null,
                ParentId: '',
                AllParentId: '',
                Remarks: '',
                SortNum: null,
                Level: 1,
                DeptTypeCode: '',
                LeaderId: '',
                LeaderName: '',
                ExecutiveId: '',
                ExecutiveName: '',
            },

            rules: {
                OrgName: [
                    { required: true, message: '请输入部门名称', trigger: 'blur' },
                    { max: 20, message: '长度在 30 个字符以内', trigger: 'blur' },
                ],
                AllParentId: [
                    { required: true, message: '请选择上级部门', trigger: 'change' },
                ],
                OrgType: [
                    { required: true, message: '请选择部门类型', trigger: 'blur' },
                ],
                SortNum: [
                    { required: true, message: '请输入序号', trigger: 'blur' },
                ],
            },

            // 外部部门【部门类型=9（外部部门）的，部门编码、部门类型不允许修改，部门类型!=9的，不允许改成9】
            isExternal: null,
        };
    },
    created() {
        this.initForm();
    },
    methods: {
        async initForm() {
            this.loading = true;
            const projectCode = this.$takeTokenParameters('ProjectCode');
            this.superiorDepartment = await this.getSuperiorDepartment(projectCode);
            this.deptTypeLIst = await this.getDeptTypeLIst();
            if (this.editType === 'editDepartment') {
                const initData = await this.initMechanism(this.currentNode.OrgId);
                this.form = Object.assign(this.form, initData);
                this.form.AllParentId = this.form.AllParentId.split(',').filter(item => item !== '');
                if (this.form.OrgType === 9) {
                    this.isExternal = true;
                } else {
                    this.isExternal = false;
                }
            } else if (this.editType === 'newDepartment') {
                this.form.TenantName = this.$takeTokenParameters('TenantName');
                this.form.TenantId = this.$takeTokenParameters('TenantId');
                this.form.AllParentId = this.currentNode.OrgId;
            }
            this.loading = false;
        },

        // 选择上级部门
        selectTenant(selectData) {
            this.form.ParentId = selectData[selectData.length - 1];
        },

        // 选择主管领导
        selectUser(type) {
            const userTenantId = this.$takeTokenParameters('TenantId');
            const addTableData = {
                showRadio: true,
                isShowNo: true,
                headerData: [{
                    title: '用户ID',
                    field: 'Id',
                }, {
                    title: '用户名',
                    field: 'RealName',
                }, {
                    title: '登录名',
                    field: 'LoginName',
                }],
                requestUrl: '/platform/tenant_user_manage/table_list',
                requestParameter: `&TenantId=${userTenantId}&UserType=1`,
            };
            let callBackFun = null;
            if (type === 'selectLeader') {
                callBackFun = this.selectLeaderCallBack;
            } else if (type === 'selectExecutive') {
                callBackFun = this.selectExecutiveCallBack;
            }
            this.$toast({
                title: true,
                text: '添加用户',
                type: 'eject',
                width: '15rem',
                height: '75%',
                viewPosition: 'components',
                t_url: 'table/addTable',
                extr: {
                    tips: '添加用户',
                    tableData: addTableData,
                    sureCallBack: callBackFun,
                },
            });
        },

        // 选择主管领导回调
        selectLeaderCallBack(data) {
            this.form.LeaderId = data[0].Id;
            this.form.LeaderName = data[0].RealName;
        },

        // 选择部门负责人回调
        selectExecutiveCallBack(data) {
            this.form.ExecutiveId = data[0].Id;
            this.form.ExecutiveName = data[0].RealName;
        },

        // 保存
        submitForm() {
            this.$refs.tenantForm.validate(async valid => {
                if (!valid) {return;}
                this.loading = true;
                this.form.ParentId = this.form.AllParentId[this.form.AllParentId.length - 1];
                this.form.AllParentId = this.form.AllParentId.toString();

                let res = null;
                if (this.editType === 'newDepartment') { // 新增
                    res = await this.getAddTenantOrganization(this.form);
                } else if (this.editType === 'editDepartment') { // 编辑
                    res = await this.getModifyTenantOrganization(this.form);
                }
                if (res) {
                    this.$message({
                        showClose: true,
                        message: '保存成功！',
                        type: 'success',
                    });
                    this.$emit('updateSucc');
                }
                this.loading = false;
            });
        },

        // 获取上级部门数据
        getSuperiorDepartment(ProjectCode = '') {
            return this.$axios.$get(`/interfaceApi/platform/tenant_org_manage/tenantorgtree_by_project/${ProjectCode}`, { defEx: true });
        },

        // 获取特殊属性选项
        getDeptTypeLIst() {
            return this.$axios.$get('/interfaceApi/platform/config_manage/list/dept_type_code', { defEx: true });
        },

        // 添加租户组织信息,并同步到基础服务平台
        getAddTenantOrganization(formData) {
            return this.$axios.$post('/interfaceApi/platform/tenant_org_manage/create_and_sync', formData, { defEx: true });
        },

        // 编辑初始化机构
        initMechanism(orgId = '') {
            return this.$axios.$get(`/interfaceApi/platform/tenant_org_manage/info/${orgId}`, { defEx: true });
        },

        // 修改租户组织信息，并同步到基础服务平台
        getModifyTenantOrganization(formData) {
            return this.$axios.$put('/interfaceApi/platform/tenant_org_manage/modify_and_sync', formData, { defEx: true });
        },
    },
};

</script>
<style lang="stylus" scoped>
    .silderEdit
        padding 0 0.2rem;
        .el-form
            .el-form-item
                >>>.el-form-item__content
                    height: auto
                    .el-cascader,
                    .el-select
                        width 100%
        .dialog-footer
            padding 0.2rem 0 0;
            display flex
            justify-content center
            .btn
                margin 0 0.3rem;
                width: 1.3rem;
                height: 0.36rem;
                line-height 0.36rem;
                text-align center
                border-radius: 0.01rem;
                cursor pointer
            .save-btn
                background: #1577D2;
                color #fff;
            .close-btn
                border: 0.01rem solid #1577D2;
                color #1577D2
</style>
