<!--组织机构部门-->
<template>
    <div class="org-slider" v-loading="loading">
        <div class="tree-con">
            <el-tree
                ref="tree_orgSlider"
                node-key="OrgId"
                default-expand-all
                class="org-slider-tree"
                :data="treeData"
                :props="tissueTreeProps"
                :highlight-current="true"
                :expand-on-click-node="false"
                :current-node-key="currentNodeKey"
                @node-click="loadTable"
            >
                <span class="slot-t-node" slot-scope="{ node, data }">
                    <div class="parent-node" v-if="data.Childs.length>0">
                        <i v-if="node.expanded" class="iconfont iconshouqi" @click.stop="expandtree(node)"></i>
                        <i v-if="!node.expanded" class="iconfont iconzhankai" @click.stop="expandtree(node)"></i>
                        <span v-if="node.expanded" class="iconfont iconwenjianjia-zhankai" @click.stop="expandtree(node)"></span>
                        <span v-if="!node.expanded" class="iconfont iconwenjianjia-shouqi" @click.stop="expandtree(node)"></span>
                        <span>{{ data.OrgShortName || data.OrgName }}</span>
                    </div>
                    <div class="leaf-node" v-else>
                        <span class="iconfont iconlingxing"></span>
                        <span>{{ data.OrgShortName || data.OrgName }}</span>
                    </div>
                    <div class="handle-btns">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="添加"
                            placement="top"
                        >
                            <i class="iconfont icontianjia1" @click.stop="handleAdd(data)"></i>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="修改"
                            placement="top"
                        >
                            <i class="iconfont iconxiugai" @click.stop="handleEdit(data)"></i>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="删除"
                            placement="top"
                            v-if="data.OrgType != 9"
                        >
                            <i class="iconfont iconlajitong" @click.stop="handleDelete(data)"></i>
                        </el-tooltip>
                    </div>
                </span>
            </el-tree>
        </div>
        <el-dialog
            title="部门管理"
            width="10rem"
            :visible.sync="showEditDialog"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <sliderEdit
                v-if="showEditDialog"
                :current-node="currentNode"
                :edit-type="editType"
                @updateSucc="reloadTree"
                @close="closeEditDialog"
            ></sliderEdit>
        </el-dialog>
    </div>
</template>

<script>
import sliderEdit from './sliderEdit';
import { eventBus } from '@/common/eventBus.ts';
export default {
    name: 'org-slider',
    components: { sliderEdit },
    data() {
        return {
            loading: false,
            treeData: [],
            tissueTreeProps: {
                label: 'OrgName',
                children: 'Childs',
            },
            defaultKey: [], // 默认展开节点id集合
            currentNodeKey: '', // 当前节点key
            menuVisible: false,
            currentNode: {}, // 当前节点数据
            showEditDialog: false, // 显示添加编辑弹框
            editType: '',
        };
    },
    watch: {},
    computed: {},
    created() {
        this.initTree();
    },
    methods: {
        // 展开节点
        expandtree(node) {
            node.expanded = !node.expanded;
            if (node.expanded) {
                this.defaultKey = [];
                this.defaultKey.push(node.data.id);
            } else {
                this.defaultKey = [];
            }
        },
        async initTree() {
            this.loading = true;
            const tenantid = this.$takeTokenParameters('TenantId');
            this.treeData = await this.getTenantOrganizationTree_tenant(tenantid);
            if ((this.treeData || []).length > 0) {
                this.$nextTick(() => {
                    this.currentNodeKey = this.treeData[0].OrgId;
                    this.$refs.tree_orgSlider.setCurrentKey(this.treeData[0].OrgId);
                });
                this.loadTable(this.treeData[0]);
            }
            this.loading = false;
        },
        // 加载表格
        loadTable(data) {
            eventBus.$emit('load-user-table', data.OrgId);
        },
        // 添加部门
        handleAdd(data) {
            this.currentNode = data;
            this.editType = 'newDepartment';
            this.showEditDialog = true;
        },
        // 修改部门
        handleEdit(data) {
            this.currentNode = data;
            this.editType = 'editDepartment';
            this.showEditDialog = true;
        },
        // 删除部门
        handleDelete(data) {
            this.$confirm('是否删除？', '确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                const tenantId = this.$takeTokenParameters('TenantId');
                const deleteDate = {
                    OrgIds: [data.id],
                    TenantId: tenantId,
                };
                const row = await this.deleteInstitutionalRequst(deleteDate);
                if (row) {
                    this.$message({
                        showClose: true,
                        message: '删除成功！',
                        type: 'success',
                    });
                    this.initTree();
                }
            });
        },
        // 重新加载树
        async reloadTree() {
            this.showEditDialog = false;
            this.initTree();
        },
        closeEditDialog() {
            this.showEditDialog = false;
        },

        // 删除请求
        deleteInstitutionalRequst(deleteDate) {
            return this.$axios.$delete('/interfaceApi/platform/tenant_org_manage/delete_and_sync', deleteDate, { defEx: true });
        },

        // 获取部门树
        getTenantOrganizationTree_tenant(TenantId) {
            return this.$axios.$get(`/interfaceApi/platform/tenant_org_manage/tenantorgtree?TenantId=${TenantId}`, { defEx: true });
        },
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.org-slider
    width 100%;
    height 100%
    background #fff
    overflow hidden
    float left
    padding 0.2rem 0;
    .new-module
        height .54rem
        background #F8F8F8
        border-bottom 1px solid #D7D7D7
        line-height .54rem
        color #333
        cursor pointer
        .iconxianshi_tianjia
            margin 0 .09rem 0 .2rem
        &:hover
            color #5588F1
    .tree-con
        height 100%
        overflow-y auto
        overflow-x auto
        .slot-t-node
            line-height .38rem
            height .38rem
            .parent-node
                .iconzhankai
                    color #5588F1
                .iconshouqi
                    color #F19F26
                .iconwenjianjia-shouqi
                    color #5588F1
                .iconwenjianjia-zhankai
                    color #F19F26
                .iconfont
                    margin-right .1rem
            .leaf-node
                color #666666
                .iconlingxing
                    color #979EA7
                    margin 0 .1rem 0 0
                    font-size .14rem
            .handle-btns
                display none;
                position absolute
                right 0.2rem;
                top 0rem;
                width 1rem;
                height 0.44rem;
                justify-content flex-end
                align-items center
                .iconfont
                    font-size 0.16rem;
                    width: 0.4rem;
                    height: 0.2rem;
                    text-align: center
                    line-height: 0.2rem
                    color: #333333;
                    border-radius: 0.02rem;
                    &:hover
                        background: #21BC9A;
                        color #fff
    >>>.el-tree
        padding: 0 0.2rem
        .el-tree-node
            position relative
            .el-tree-node__content
                height 0.44rem;
            .el-tree-node__expand-icon
                display none;
            &.is-current
                &:not(.is-expanded)
                    background-color: #f0f7ff;
                &.is-expanded .el-tree-node
                    background-color: #fff;
                >.el-tree-node__content>.slot-t-node>.leaf-node
                    color #FE7905
                    .iconfont
                        color #FE7905
            &:not(.is-expanded):hover
                background #F5F7FA
            &.is-expanded .el-tree-node:not(.is-expanded):hover
                background #F5F7FA
            &:not(.is-expanded):hover
                >.el-tree-node__content >.slot-t-node >.handle-btns
                    display flex;
            &.is-expanded >.el-tree-node__content:hover
                >.slot-t-node >.handle-btns
                    display flex;
    >>> .el-dialog__wrapper
        z-index 1000!important;
    >>> .v-modal
        z-index 990!important;
</style>
<style lang="stylus">
    .el-popup-parent--hidden {
        padding-right: 0 !important;
    }
</style>
